<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "ConquerProcrastinationAndGetWriting",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="ConquerProcrastinationAndGetWriting">
      <SuawParagraph text="You’re excited to write. You talk about doing it. You make plans to do it. Trouble is, you’re not writing." />
      <SuawParagraph
        text="You’re not alone. Developing the discipline to write consistently can be tough, particularly when you are first starting out. Everything else seems to get in the way. Here’s how to get yourself on track when you find yourself procrastinating."
      />
      <SuawHeading level="3" content="Tackling Procrastination" />
      <SuawParagraph
        text="Life happens. Priorities shift. Your family needs you; work asks more of you. There are a hundred and one reasons why you can’t find the time to write today. We all start the day with good intentions, we tell ourselves we’ve blocked off the time, whether it’s our lunch hour, or after the kids are asleep. But when the moment strikes, invariably something else has come up and wormed its way into your mind, whispering “I am more important.” Or worse, “I’m tired, there’s no use trying now, my brain is mush.” Or “I’m slammed today. I’ll write more tomorrow. Promise.”"
      />
      <SuawParagraph
        text="But then tomorrow becomes today. Literally. And maybe you get your writing in. But if you find the pattern repeating, here are a few things to keep in mind to get yourself back on track."
      />
      <SuawHeading level="3" content="Your Project Begins with a Single Sentence" />
      <SuawParagraph
        text="Each time you sit down to write, you are taking a step toward completing your project. Even if you write for thirty minutes, you are making progress. Appreciate every moment you spend writing; pat yourself on the back. If you feel good about the time you spent writing, you’ll want to do it again. And soon."
      />
      <SuawHeading level="3" content="Make Realistic Commitments" />
      <SuawParagraph
        text="Be honest about the time you have. Chances are you have more time than you think, but if you are not realistic about the commitments you already have in your life, you will continue to let your writing fall by the wayside."
      />
      <SuawHeading level="3" content="Don’t Set Unrealistic Goals" />
      <SuawParagraph
        text="It’s okay to write for a small period of time. You can accomplish lot in an hour. Even the ten minutes while you’re waiting at the doctors can be productive."
      />
      <SuawParagraph
        text="But don’t think you’re going to write the most perfect prose in that time. Know going in how much time you’ll need to accomplish the goal you’ve set for yourself that day. If you’re editing your entire book, respect that it will take you several hours over several days. As a benchmark, the average word count is 1000 words per hour, once you’ve developed your rhythm."
      />
      <SuawHeading level="3" content="Set Up a Time and a Safe Environment to Write with Others" />
      <SuawParagraph
        text="I’m a huge believer in finding a consistent time for writing. It helps your loved ones if they know on this day and time, you’re going to be focused on your writing. If you can, find others to write with you, join a writing group, make the commitment where you have some accountability, where people expect to see you."
      />
      <SuawHeading level="3" content="Surround Yourself with Supportive, Non-Competitive Colleagues" />
      <SuawParagraph
        text="Most writers are not anti-social. While writing can be done while you’re alone, you’ll continue to feel motivated and inspired if you have others writing with you. It’s like going to the gym – we all do better with a buddy."
      />
      <SuawHeading level="3" content="Schedule Time NOT to Write" />
      <SuawParagraph
        text="I’m suggesting that you’ll avoid procrastination if you schedule time in your week to write, but you must also respect the other demands on your time – you have family, work, friends; all those hundred and one other things that want your time and attention. Make time for them and you’ll value your writing time even more."
      />
      <SuawParagraph text="Have other suggestions? Let me know how you stay focused and keep working on your writing goals." />
      <SuawParagraph text="<em>* Additional credit to Rennie Saunders, for allowing this expansion of the Shut Up & Write! Method.</em>" />
    </ArticleFrame>
  </SuawMainContent>
</template>
